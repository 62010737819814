@import "ui/_vars";

.btn {
  border: none;
  text-transform: uppercase;
  border-radius: 1.5em;
  font-weight: bolder;
  font-family: White Festive, sans-serif;
  padding: .2em 1em 0;
  font-size: 1.5em;
  letter-spacing: .05em;
  line-height: 1;
  cursor: pointer;
  transition: background-color .2s ease-in;
  color: $light;
  background-color: $accent-color;
  text-decoration: none;
  text-align: center;
  &:hover {
    filter: brightness(.9); }
  > * {
    padding: 0; } }

@for $i from 1 through length($rooms) {
  .btn-color-room-#{$i} {
    background-color: nth($rooms, $i);
    color: $light; } }

.btn-color-accent-dark {
  background-color: $accent-color-dark;
  color: $light; }
.btn-color-accent-2 {
  background-color: $accent-color-2;
  color: $light; }
.btn-color-accent-3 {
  background-color: $accent-color-3;
  color: $light; }
.btn-color-accent-4 {
  background-color: $accent-color-4;
  color: $light; }
.btn-color-accent-5 {
  background-color: $accent-color-5;
  color: $light; }
.btn-color-destructive {
  background-color: $error;
  color: $light; }
.btn-color-success {
  background-color: $success;
  color: $light; }
.btn-color-warning {
  background-color: $warning;
  color: $light; }

.btn-color-none {
  background-color: transparent; }


.btn-size-small {
  padding: .15em .75em;
  font-size: 1.25em; }

.btn-size-large {
  padding: .5em 1.5em; }

.btn-circle {
  position: relative;
  padding: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center; }

.btn-circle.btn-size-large {
  width: 64px;
  height: 64px; }

.btn-size-xl {
  @extend .btn-circle;
  width: 128px;
  height: 128px; }

.btn-circle.btn-size-small {
  width: 36px;
  height: 36px; }

.btn-full-width {
  display: flex;
  justify-content: center; }

.btn-icon img {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-top: -.25em; }
