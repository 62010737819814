@import "../../sass/ESSENTIALS/vars";

.section {
  overflow: hidden;
  padding: 5% 7.5%;
  position: relative;
  background-color: $accent-color-dark;
  min-height: 70vh;
  h2 {
    margin-top: 2rem; } }

.section-main {
  min-height: 100vh;
  padding: 0; }

@media (min-width: 768px) {
  .section-noise {
    background-image: url("bg.png"); } }
