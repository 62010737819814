// ------------------------------ COLORS ------------------------------ //
$accent-color: #482c29;
$accent-color-2: #d3b098;
$accent-color-3: #38211e;
$accent-color-4: #594630;
$accent-color-5: #c77a7a;
$accent-color-dark: #1c1614;
$lightred: #dd6565;
$light: #fafafa;
$grey: #c7c7c7;
$dark: black;
$warning: #c4960d;
$error: #7f0606;
$success: #30893f;

$room-1: #724444;
$room-2: #5e9782;
$room-3: #b35d41;
$room-4: #844644;
$room-5: #405572;
$room-6: #5e7240;

$rooms: $room-1, $room-2, $room-3, $room-4, $room-5, $room-6;

$price-1: #392222;
$price-2: #0c262c;
$price-3: #0c2c22;
$price-4: #272727;

$prices: $price-1, $price-2, $price-3, $price-4;
// ------------------------------ PATHS ------------------------------ //

$media: "../assets/";
$images: $media + 'images/';
$icons: $media + 'icons/';
$fonts: $media + 'fonts/';
$bg: $images + 'bg/';

// ------------------------------ TYPOGRAPHY ------------------------------ //
$font-size: 20px;
