@import "../../sass/ESSENTIALS/vars";

.not-found {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  justify-content: space-evenly;
  p {
    margin-bottom: 1em; } }

.not-found-img {
  width: 50vw;
  pointer-events: none;
  user-select: none;
  max-width: 240px;
  align-self: flex-end;
  filter: brightness(10); }
