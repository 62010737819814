@import "../../sass/ESSENTIALS/vars";

@font-face {
  font-family: "White Festive";
  src:  url("../../assets/fonts/WhiteFestive.otf"); }

@font-face {
  font-family: Coolvetica;
  src:  url("../../assets/fonts/Coolvetica.woff"); }


h1, h2, h3, h4, h5, h6, p {
  color: $light;
  margin: 0;
  padding: 0; }

h1, h2, h3 {
  font-family: "White Festive", sans-serif;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: .05em;
  text-shadow: 1px 1px 1px $dark; }

h1 {
  font-size: 5rem; }

h2 {
  font-size: 4.25rem;
 }  // font-weight: lighter

h3 {
  font-size: 2rem; }

h5 {
  letter-spacing: .05em; }

h6 {
  font-weight: bolder !important;
  font-style: italic;
  letter-spacing: .1em; }

h4, h5,h6, p, a {
  font-weight: lighter;
  line-height: 1.6;
  font-family: "Coolvetica", sans-serif; }

p {
  font-size: $font-size;
  padding: .5em; }

.text-link {
  color: $accent-color-2;
  transition: color .3s;
  &:hover {
    color: $light; } }
