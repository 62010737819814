@import "ui/_vars";

@keyframes chat {
  0%, 25%, 100% {
    transform: none; }
  5% {
    transform: scale(1.1); }
  10% {
    transform: scale(1.1) rotate(-21deg); }
  15% {
    transform: scale(1.075) rotate(14deg); }
  20% {
    transform: scale(1.05) rotate(-7deg); }
  // 70%
 }  //   transform: rotate(15deg)

.chat-fab {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  margin: 2.5%;
  transition: .2s ease-out;
  right: 0;
  width: 54px;
  height: 54px;
  a {
    box-shadow: 0 0 10px black;
    @media (min-width: 720px) {
        &:hover, &:active {}
        animation: none;
        transform: scale(1.1); }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $accent-color;
    border-radius: 50%;
    @media (min-width: 720px) {
      animation: chat 2.5s infinite; }

    img {
      width: 32px; } }
  .filled {
    transition: opacity .3s;
    opacity: 1; }
  &:hover, &:active {
    .tooltip {
      position: relative;
      transform: none;
      opacity: 1; } } }



.tooltip {
  margin-bottom: -54px;
  float: right;
  color: $light;
  background-color: #444;
  font-family: monospace;
  padding: .3em .5em;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  // white-space: nowrap
  width: 256px;
  margin-right: 3em;
  border-radius: 1em;
  transition: .3s;
  transform: scale(0);
  transform-origin: right;
  opacity: 0; }


.beta-warning {
  cursor: pointer;
  background-color: $warning;
  color: $light;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  left: 50%;
  width: 192px;
  height: 164px;
  border-radius: 50%;
  transform: translate(-50%,50%);
  box-shadow: 0 0 30px rgba(0,0,0,.4);
  h5 {
    text-align: center;
    margin-top: (164px /4);
    font-family: sans-serif;
    font-size: 1.5em; }
  &:hover, &:active {
    .tooltip {
      padding: 1em;
      left: -2.5em;
      margin-right: auto;
      margin-top: -92px;
      transform-origin: center;
      transform: none;
      opacity: 1; } } }
